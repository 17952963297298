import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DetailLayout from '../../containers/layout/detail-layout'
import BoxImageLarge from '../../containers/elements/box-large-image/section-home'

const ProjectDetail = ({ data, location, pageContext }) => (
  <DetailLayout
    location={location}
    pageContext={pageContext}
    pageData={data.page.edges[0].node}
  >
    {data.relatedNews.edges && data.relatedNews.edges.length > 0 && (
      <BoxImageLarge
        path="/actueel/nieuws"
        title="Nieuwsberichten"
        items={data.relatedNews.edges.map((edge) => edge.node)}
        split={true}
        hideAll={true}
      />
    )}

    {data.related.edges && data.related.edges.length > 0 && (
      <BoxImageLarge
        path="/themas/projecten"
        title="Gerelateerde projecten"
        buttontext="projecten"
        items={data.related.edges.map((edge) => edge.node)}
        padding={false}
      />
    )}
  </DetailLayout>
)

export const query = graphql`
  query ProjectDetailQuery($id: String, $categories: [String]) {
    page: allContentfulProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          content {
            json
          }
          qa {
            id
            title
            description: content {
              json
            }
          }
          themes {
            id
            title
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          contact {
            id
            type
            name
            title
            township
            phone
            mobile
            email
            image {
              fixed(
                width: 100
                height: 100
                cropFocus: FACE
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
          downloads {
            id
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          timeline {
            ... on Node {
              ... on ContentfulNieuwsbericht {
                id
                title
                slug
                date(locale: "nl-NL", formatString: "DD MMMM YYYY")
                internal {
                  type
                }
                description {
                  description
                }
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                  fluid(maxWidth: 400) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }

              ... on ContentfulPublicatie {
                id
                title
                slug
                date(locale: "nl-NL", formatString: "DD MMMM YYYY")
                internal {
                  type
                }
                description {
                  description
                }
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                  fluid(maxWidth: 400) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }

              ... on ContentfulParagraaf {
                id
                title
                date(locale: "nl-NL", formatString: "DD MMMM YYYY")
                content {
                  json
                }
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                  fluid(maxWidth: 400) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    related: allContentfulProject(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        themes: { elemMatch: { title: { in: $categories } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          themes {
            title
          }
          image {
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    relatedNews: allContentfulNieuwsbericht(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        themes: { elemMatch: { title: { in: $categories } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          themes {
            title
          }
          image {
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

ProjectDetail.propTypes = {
  headingStyle: PropTypes.object,
}

export default ProjectDetail
